import React from 'react'
import { withTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import { useHistory } from 'react-router'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { Separator } from '@junglescout/edna'

import {
  LISTING_BUILDER_BASE,
  RANK_TRACKER_V2_BASE,
  COMPETITIVE_INTELLIGENCE_URL,
  COMPETITIVE_INTELLIGENCE_MARKET_URL
} from 'constants/routes'
import { PERMISSION_KEYS } from 'constants/account/permissions'
import { Features } from 'constants/feature_names'
import { SEGMENT_LOCATION } from 'constants/listing_builder/listing_builder'
import { JSPRO_BUCKET } from 'constants/s3'

import { hasAccess } from 'helpers/permissions'
import { sendCtaClickEvent } from 'helpers/segment'
import {
  competitiveIntelligencePageBreadcrumbs,
  rankTrackerGroupPageBreadcrumbs
} from 'helpers/navbar'

import { useFeatureFlag } from 'hooks/feature_flag'

import { BackButton } from 'ui_elements/BackButton/BackButton'
import { NavbarWrapper } from 'ui_elements/NavbarWrapper'
import { ImpersonationBar } from 'ui_elements/ImpersonationBar'
import { Breadcrumb } from 'ui_elements/Breadcrumb/Breadcrumb'

import { AlertsDropdown } from 'src/js/components/navbar/AlertsDropdown/AlertsDropdown'
import { AccountDropdown } from 'src/js/components/navbar/AccountDropdown/AccountDropdown'
import { AmazonAccountDropdown } from 'src/js/components/navbar/AmazonAccountDropdown/AmazonAccountDropdown'
import { ChatBotSlideout } from 'src/js/components/chatbot/ChatBotSlideout'

function getNavBarColour(props) {
  const { colors } = props.theme
  if (props.isFullScreenOverlay) {
    return colors.grey100
  }

  return colors.white
}
const LOGO_URL = `${JSPRO_BUCKET}/images/js-icon-small.svg`

const StyledNavbarWrapper = styled(NavbarWrapper)`
  background-color: ${getNavBarColour};
  align-items: center;
  justify-content: ${props => (props.useNewUI ? 'space-between' : 'flex-end')};
  ${props => props.useNewUI && 'box-shadow: none; height: 49px'};
  padding-left: 30px;
`
const NavbarRight = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
  padding-right: ${props => (props.$isMobile ? '14px' : '30px')};
  justify-content: flex-end;
  background-color: transparent;
  gap: ${props => (props.$isMobile ? '4px' : '12px')};
`
const MobileLogo = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
    height: 35px;

    // Center the logo using absolute as we have a lot more of control
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`
const NavbarLink = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${props => props.theme.colors.grey900};
  transition: color 150ms ease;
  display: flex;
  align-items: center;
  [class^='DropdownWrapper'] {
    align-self: stretch;
    cursor: pointer;
  }
  a {
    color: ${props => props.theme.colors.white};
  }
  &:hover,
  a:hover {
    color: ${props => props.theme.colors.white};
  }
`
const StyledBackButton = styled(BackButton)`
  height: 30px;
  margin: 0;
`

export const NavbarComponent = ({
  isFullScreenOverlay,
  isSimpleNav,
  user,
  isImpersonating,
  t
}) => {
  const history = useHistory()
  const { breadCrumbName: name } = useSelector(state => state.globalData)

  const rankTrackerGroupRoute = useRouteMatch(`${RANK_TRACKER_V2_BASE}/:id`)
  const listingBuilderRoute = useRouteMatch(`${LISTING_BUILDER_BASE}/:id`)
  const competitiveIntelligenceRoute = useRouteMatch(
    COMPETITIVE_INTELLIGENCE_URL
  )
  const competitiveIntelligenceMarketRoute = useRouteMatch(
    COMPETITIVE_INTELLIGENCE_MARKET_URL
  )
  const profitOverviewRoute = useRouteMatch('/sales-analytics/profit-overview')
  const keywordFeatureRoute = useRouteMatch('/keyword') // targets keyword scout, keyword list, rank tracker, and listing builder

  const routeWithNewUI =
    listingBuilderRoute ||
    rankTrackerGroupRoute ||
    competitiveIntelligenceRoute ||
    competitiveIntelligenceMarketRoute ||
    profitOverviewRoute ||
    keywordFeatureRoute

  const renderImpersonationBar = () => {
    if (isImpersonating) {
      return <ImpersonationBar />
    }

    return null
  }

  const isMobileNavEnabled = isMobile

  const isChatBotEnabled = useFeatureFlag(Features.AI_CHATBOT)

  const renderAlerts = () => {
    const { permissions } = user

    if (!hasAccess(permissions, PERMISSION_KEYS.ALERTS)) {
      return null
    }

    return <AlertsDropdown />
  }

  const renderLeftNav = () => {
    if (listingBuilderRoute !== null) {
      const { params } = listingBuilderRoute

      return (
        <StyledBackButton
          linkText={t('generic.exitBuilder', 'Exit Builder')}
          onClick={() => {
            sendCtaClickEvent({
              text: 'Exit Builder',
              location: SEGMENT_LOCATION,
              extraProps: {
                listingId: params.id === 'new' ? null : params.id
              }
            })

            history.push(LISTING_BUILDER_BASE)
          }}
        />
      )
    }

    // This is a new approach to render Breadcrumbs in the top Navbar for Rank Tracker Group page.
    // Maybe later we will use this for more pages and this code could be made more generic.
    if (rankTrackerGroupRoute !== null) {
      const breadCrumbItems = rankTrackerGroupPageBreadcrumbs({
        t,
        history,
        name
      })

      return breadCrumbItems?.length ? (
        <Breadcrumb items={breadCrumbItems} />
      ) : null
    }

    if (competitiveIntelligenceMarketRoute) {
      const breadCrumbItems = competitiveIntelligencePageBreadcrumbs(t, name)

      return breadCrumbItems?.length ? (
        <Breadcrumb items={breadCrumbItems} />
      ) : null
    }

    return null
  }

  const renderRightNav = () => {
    if (isSimpleNav) {
      return null
    }

    return (
      <NavbarRight $isMobile={isMobileNavEnabled}>
        {isMobileNavEnabled && (
          <MobileLogo>
            <img src={LOGO_URL} alt="Jungle Scout logo" />
          </MobileLogo>
        )}
        {isChatBotEnabled && <ChatBotSlideout />}
        {!isMobileNavEnabled && <AmazonAccountDropdown />}
        {!isFullScreenOverlay && <NavbarLink>{renderAlerts()}</NavbarLink>}
        {isMobileNavEnabled && (
          <Separator orientation="vertical" margin="0 6px 0 0" />
        )}
        <AccountDropdown isMobile={isMobileNavEnabled} />
      </NavbarRight>
    )
  }

  return (
    <>
      <StyledNavbarWrapper
        isSimpleNav={isSimpleNav}
        isFullScreenOverlay={isFullScreenOverlay}
        useNewUI={routeWithNewUI !== null}>
        {renderLeftNav()}
        {renderRightNav()}
        {renderImpersonationBar()}
      </StyledNavbarWrapper>
    </>
  )
}

NavbarComponent.defaultProps = {
  isImpersonating: false,
  isFullScreenOverlay: false,
  isSimpleNav: false,
  user: {}
}

NavbarComponent.propTypes = {
  isImpersonating: PropTypes.bool,
  isFullScreenOverlay: PropTypes.bool,
  isSimpleNav: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any)
}

const wrappedNavbar = withTranslation('generic')(withTheme(NavbarComponent))

export { wrappedNavbar as Navbar }
