import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { logoutUser } from 'actions/sessions'

import { TEXT_STYLES } from 'constants/text_styles'
import { SETTINGS_URLS } from 'constants/routes'

import { canViewTeamSettings } from 'helpers/team'

import { ButtonType, DropdownMenu, Icon, Avatar } from '@junglescout/edna'
import { NakedButton } from 'ui_elements/NakedButton/NakedButton'
import { ConnectToAmazonButton } from 'src/js/containers/common/ConnectToAmazonButton'
import { slideInDown, slideOutUp } from 'src/js/components/common/animations'
import { TruncatedLine } from 'ui_elements/TruncatedLine/TruncatedLine'
import { ACCOUNT_SETTINGS_ICONS } from 'constants/account'
import { sendCtaClickEvent, sendNavClickEvent } from 'helpers/segment'
import { TOP_NAV } from 'constants/activation_points/navbar'

const DropdownContainer = styled(DropdownMenu)`
  padding: 12px;
  &[data-state='open'] {
    animation: ${slideInDown} 200ms ease-in-out;
  }
  &[data-state='closed'] {
    animation: ${slideOutUp} 200ms ease-in-out;
  }
`
const AccountInfoItem = styled(DropdownMenu.Item)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start !important;
  padding: 8px;
  gap: 20px;
`
const Divider = styled(DropdownMenu.Divider)`
  margin: ${props => props.margin};
`
const Item = styled(DropdownMenu.Item)`
  ${TEXT_STYLES.H4Black};
  font-weight: 400 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start !important;
  padding: 8px;
  gap: 8px;
`
const UserDetails = styled.div`
  max-width: 203px;
`
const FullName = styled(TruncatedLine)`
  ${TEXT_STYLES.H2Black};
`
const Email = styled(TruncatedLine)`
  color: ${props => props.theme.colors.grey600};
`
const ButtonWrapper = styled.div`
  padding: 0 8px 8px 8px;
`
const AccountPhotoButton = styled(NakedButton)``

export const AccountDropdown = ({ isMobile }) => {
  const { t } = useTranslation(['generic', 'account'])
  const history = useHistory()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const {
    user: {
      email,
      full_name: name,
      account_photo: photo,
      is_account_owner: isAccountOwner,
      is_account_owner_or_manager: isAccountOwnerOrManager
    },
    membershipInfo: { user_limit: userLimit, hasActiveMembership }
  } = useSelector(state => state.globalData)
  const avatarName = name || email

  const { stripe_plan: stripePlan } = useSelector(state => state.seats)

  const hasTeamAccess = canViewTeamSettings({
    isAccountOwnerOrManager,
    userLimit,
    hasSeatStripePlan: !!stripePlan?.id
  })

  const isOwner = isAccountOwner || !hasActiveMembership

  const settingsItems = [
    {
      label: t('account:MyAccount.menu.allSettings', 'All Settings'),
      eventLabel: 'All Settings',
      link: SETTINGS_URLS.allSettings,
      iconName: 'SETTINGS_COG',
      show: true
    },
    {
      label: t('account:MyAccount.menu.subscriptions', 'Subscriptions'),
      eventLabel: 'Subscriptions',
      link: SETTINGS_URLS.subscriptions,
      iconName: ACCOUNT_SETTINGS_ICONS.subscriptions,
      show: isOwner
    },
    {
      label: t('account:MyAccount.menu.amazonSettings', 'Amazon Settings'),
      eventLabel: 'Amazon Settings',
      link: SETTINGS_URLS.amazonSettings,
      iconName: ACCOUNT_SETTINGS_ICONS.amazon,
      show: isOwner
    },
    {
      label: t('account:MyAccount.menu.billingInfo', 'Billing Information'),
      eventLabel: 'Billing Information',
      link: SETTINGS_URLS.billing,
      iconName: ACCOUNT_SETTINGS_ICONS.billing,
      show: isOwner
    },
    {
      label: t('account:MyAccount.menu.teamManagement', 'Team Management'),
      eventLabel: 'Team Management',
      link: SETTINGS_URLS.team,
      iconName: ACCOUNT_SETTINGS_ICONS.team,
      show: hasTeamAccess
    }
  ]

  return (
    <DropdownContainer
      open={isOpen}
      onOpenChange={showing => {
        setIsOpen(showing)
        if (showing) {
          sendNavClickEvent({
            location: TOP_NAV,
            navItem: 'User Account Dropdown'
          })
        }
      }}
      width="324px"
      align="end"
      side="bottom"
      sideOffset={10}
      trigger={
        <AccountPhotoButton aria-label="Account Info" $isMobile={isMobile}>
          <Avatar fullName={avatarName} image={photo} />
        </AccountPhotoButton>
      }>
      <AccountInfoItem
        action={() => {
          history.push(SETTINGS_URLS.accountInfo)
          sendCtaClickEvent({
            location: 'Account Dropdown',
            text: 'Account Info Clicked'
          })
        }}>
        <Avatar
          fullName={avatarName}
          image={photo}
          size="55px"
          fontSize="24px"
        />
        <UserDetails>
          <FullName>{name}</FullName>
          <Email>{email}</Email>
        </UserDetails>
      </AccountInfoItem>
      <Divider margin="8px 8px 16px 8px" />
      <ButtonWrapper>
        <ConnectToAmazonButton
          onClick={() => {
            setIsOpen(false)
            sendCtaClickEvent({
              location: 'Account Dropdown',
              text: 'Connect an Amazon Account'
            })
          }}
          iconName="PLUS"
          btnType={ButtonType.TERTIARY}
          width="100%"
          margin="0"
          btnText={t(
            'account:MyAccount.menu.connectAccount',
            'Connect an Amazon Account'
          )}
        />
      </ButtonWrapper>
      {settingsItems.map(({ label, eventLabel, iconName, link, show }) => {
        if (!show) {
          return null
        }
        return (
          <Item
            key={link}
            action={() => {
              sendCtaClickEvent({
                location: 'Account Dropdown',
                text: eventLabel
              })

              history.push(link)
            }}>
            {/* removed aria label here as we get the accessible name from menu item via the label */}
            <Icon name={iconName} aria-label="" /> {label}
          </Item>
        )
      })}
      <Divider margin="8px" />
      <Item action={() => dispatch(logoutUser())}>
        <Icon name="LOG_OUT" aria-label="" />
        {t('generic:LogOut', 'Log Out')}
      </Item>
    </DropdownContainer>
  )
}

AccountDropdown.propTypes = {
  isMobile: PropTypes.bool
}

AccountDropdown.defaultProps = {
  isMobile: false
}
